import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.span`
    display: inline-flex;
    align-items: center;
    margin-right: ${rem(20)};
    margin-bottom: ${rem(10)};
    cursor: pointer;
`;

export const ImageSection = styled.div`
    position: relative;
    width: ${rem(32)};
    height: ${rem(32)};
    overflow: hidden;
    border-radius: 50%;
    margin-right: ${rem(10)};
    margin-bottom: ${rem(2)};
`;

export const Image = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
`;

export const Title = styled.span<{ isBold?: boolean }>`
    font-weight: ${(prop) => (prop.isBold ? prop.theme.fontWeights.textBold : prop.theme.fontWeights.textRegular)};
    color: ${({ theme }) => theme.colors.secondaryGrey};
`;
